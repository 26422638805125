import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"
import Layout from 'components/Layout';

// import img_bene_line2 from 'assets/images/svg/bene-line2.svg';
// import img_bene_line from 'assets/images/svg/bene-line.svg';
// import img_tw_workshop from 'assets/images/jpg/tw-workshop.jpg';
// import img_tw_designs from 'assets/images/jpg/tw-designs.jpg';

export const query = graphql`
query {
    img_tw_workshop: file(relativePath: {eq: "jpg/tw-workshop.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    img_tw_designs: file(relativePath: {eq: "jpg/tw-designs.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    img_tw_designsystem: file(relativePath: {eq: "jpg/tw-design-system.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    img_tw_result: file(relativePath: {eq: "jpg/tw-result.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    img_werchter_mobile: file(relativePath: {eq: "png/werchter-mobile.png"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img_werchter_mobile_2: file(relativePath: {eq: "png/werchter-mobile-2.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_werchter_mobile_3: file(relativePath: {eq: "png/werchter-mobile-3.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_werchter_styletile: file(relativePath: {eq: "jpg/werchter-styletile.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_werchter_grid: file(relativePath: {eq: "jpg/werchter-grid.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_werchter_logo: file(relativePath: {eq: "png/werchter-logo.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`

const TwPage = ({ data }) => {
        return (
            <Layout pageName="tw">
                <Helmet>
                    <title>Thames Water - Dries Standaert - Digital product designer</title>
                    <meta name="description" content="Case study on designing a solution to report a problem for Thames Water"/>
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                    <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap" rel="stylesheet"/>
                    <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"/>
                    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
                </Helmet>
                <main id="main-content">
                    <section className="c-row c-row--alpha c-row-hero">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp2-10 f-col-bp3-8 c-hero" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-anchor="hero">
                                <h1>Designing a solution to report a problem</h1>
                                <ul className="o-list o-list--horizontal c-tags--hero">
                                    <li className="o-list__item c-tag">IA</li>
                                    <li className="o-list__item c-tag">Prototyping</li>
                                    <li className="o-list__item c-tag">Visual design</li>
                                    <li className="o-list__item c-tag">Design system</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="c-row c-row--beta u-bgcolor-tw-base">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp2-12 f-col-bp2-shift-0 f-col-bp3-12">
                                <div className="c-tile__content c-tile__content--full">
                                    <figure>
                                        <Img fluid={data.img_tw_result.childImageSharp.fluid} alt="TW result" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*
                    <section className="c-row c-row--beta">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp3-6 c-tile">
                            <div className="">
                                <figure className="u-ratio u-ratio-1x1">
                                    <Img fluid={data.img_tw_workshop.childImageSharp.fluid} alt="TW workshops" />
                                </figure>
                            </div>
                        </div>
                        <div className="f-col-bp1-4 f-col-bp3-6 c-tile">
                            <div className="">
                                <figure className="u-ratio u-ratio-1x1">
                                    <Img fluid={data.img_tw_designs.childImageSharp.fluid} alt="TW designs" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                    */}
                    {/*
                    <section className="c-row c-row--padding u-bgcolor-gradient" data-aos="fade-up">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp1-shift-0 f-col-bp3-8 f-col-bp3-shift-2">
                                <div className="c-mockup">
                                    <div className="c-mockup-mobile c-mockup-mobile--1 is-open">
                                        <Img fluid={data.img_werchter_mobile_2.childImageSharp.fluid} alt="Werchter mobile" />
                                    </div>
                                    <div className="c-mockup-mobile c-mockup-mobile--3 is-open">
                                        <Img fluid={data.img_werchter_mobile.childImageSharp.fluid} alt="Werchter mobile" />
                                    </div>
                                    <div className="c-mockup-mobile c-mockup-mobile--2 is-open">
                                        <Img fluid={data.img_werchter_mobile_3.childImageSharp.fluid} alt="Werchter mobile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    */}
                    <section className="c-row c-row--alpha">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp2-8 f-col-bp3-7">
                                <div className="c-tile__content c-tile__content--text">
                                    <h2>Fixing leaks and customer service</h2>
                                    <p>As the UK's largest wholesale water and wastewater services provider Thames Water is committed to minimise leaks and offering a responsive customer service.</p>
                                    <p>We set out to understand the existing user experience by identifying pitfalls and opportunities. Through workshops and interviews we were able to create personas that resembled the customers attitude and experience with Thames water on different channels. This led us to ideating future costumer experiences, which we worked out in design sprints.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                   
                    <section className="c-row c-row--beta">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp2-8 f-col-bp3-5">
                                <div className="c-tile__content c-tile__content--img">
                                    <figure className="u-ratio u-ratio-1x1">
                                        <Img fluid={data.img_tw_workshop.childImageSharp.fluid} alt="TW workshops" />
                                    </figure>
                                </div>
                            </div>
                            <div className="f-col-bp1-4 f-col-bp2-8 f-col-bp3-7 u-ver-center">
                                <div className="c-tile__content c-tile__content--text">
                                    <h2>Design sprints</h2>
                                    <p>One design sprint focussed on improving the customer experience to report a problem. With our new personas and research in place, we used this information to workshop and ideate potential ideas.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-row c-row--alpha">
                    <div className="f-grid">
                        <div className="f-col-bp1-4 f-col-bp2-8 f-col-bp3-7">
                            <div className="c-tile__content">
                                <h2>Cross-over</h2>
                                <p>My team consisted of t-shaped individuals which allowed me to cross over into various responsibilities. This meant facilitating workshops, co-creating user flows, prototyping and even coding animations and interactions.</p>
                                <div class="c-waterloader">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="40px" viewBox="0 0 80 40">
                                        <path fill="none" stroke="#009EDE" stroke-width="5" d="M-25,22.4c7.7,0,7.2-4.8,15-4.8s7.2,4.8,15,4.8s7.2-4.8,15-4.8s7.2,4.8,15,4.8s7.2-4.8,15-4.8
                                        s7.2,4.8,15,4.8s7.2-4.8,15-4.8s7.2,4.8,15,4.8s7.2-4.8,15-4.8s7.2,4.8,15,4.8s7.2-4.8,15-4.8s7.2,4.8,15,4.8s7.2-4.8,15-4.8
                                        s7.2,4.8,15,4.8s7.2-4.8,15-4.8">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    <section className="c-row c-row--beta">
                        <div className="f-grid">
                            <div className="f-col-bp1-4 f-col-bp3-6 u-ver-center">
                                <div className="c-tile__content c-tile__content--text">
                                    <h2>Design system</h2>
                                    <p>At the same time of customer experience design sprints Thames Water was making a shift in their brand identity. To match the improved web experiences, I was responsible to build new design system that was practical accross all digital channels.</p>
                            </div>
                            </div>
                            <div className="f-col-bp1-4 f-col-bp3-6">
                                <div className="c-tile__content c-tile__content--full">
                                    <figure className="u-tablet u-break-right">
                                        <Img fluid={data.img_tw_designsystem.childImageSharp.fluid} alt="TW design system" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>                   
                    <section className="c-row u-nomargin u-bgcolor-neutral-x-light">
                        <div className="f-grid u-no-max-width">
                            <Link to="/attest/" className="f-col-bp3-6 c-tile c-tile--link">
                                <div className="c-tile__content c-tile__content--link">
                                    <p className="c-tile__link-text c-tile__link-text--prev"><span>Attest</span></p>
                                </div>
                            </Link>
                            <Link to="/cobbled-climbs/" className="f-col-bp3-6 c-tile c-tile--link">
                                <div className="c-tile__content c-tile__content--link">
                                    <p className="c-tile__link-text c-tile__link-text--next"><span>Cobbled Climbs</span></p>
                                </div>
                            </Link>
                        </div>
                    </section>
                </main>
            </Layout>
        )
    };

export default TwPage;

